<template>
  <div class="sidebar">
    <div class="logo">
      <router-link to="/">
        <img
          src="@/assets/DATANALAYTIQUE-LOGO-WHITE.png"
          class="logo">
      </router-link>
    </div>
    <div class="routes">
      <router-link class="link" :to="{ name: 'home' }">
        <div class="round" />
        <div class="svg"><v-svg>home</v-svg></div>
        <div class="round" />
      </router-link>
      <router-link class="link" :to="{ name: 'entry' }">
        <div class="round" />
        <div class="svg"><v-svg>pencil</v-svg></div>
        <div class="round" />
      </router-link>
      <router-link class="link" :to="{ name: 'restitution' }">
        <div class="round" />
        <div class="svg"><v-svg>doc</v-svg></div>
        <div class="round" />
      </router-link>
      <div class="link" @click="showConfirmModal = true">
        <div class="round" />
        <div class="svg"><v-svg>logout</v-svg></div>
        <div class="round" />
      </div>
    </div>
    <div>
      <div class="help">
        <a title="Besoin d'aide ?" target="_blank" href="https://docs.google.com/presentation/d/1zDKp5KKX3kWtZh_D5uYjB6jbBTxBW9r-761yB5mGxRM/edit#slide=id.p">
          <v-svg class="help-icon">help</v-svg>
        </a>
      </div>
    </div>
    <v-modal v-if="showConfirmModal">
      <h3 slot="header" style="color: black">{{ $t('entry.modal.logout') }}</h3>
      <span slot="body" style="color: black">{{ $t('entry.modal.logoutMessage') }} </span>
      <v-button slot="footer" class="small" @click="logOut">{{ $t('entry.modal.yes') }}</v-button>
      <v-button slot="footer" class="small" @click="closeConfirmModal">{{ $t('entry.modal.no') }}</v-button>
    </v-modal>
  </div>
</template>

<script>
import { logout } from '../main.js'

export default {
  name: 'Sidebar',
  data() {
    return {
      showConfirmModal: false
    }
  },
  methods: {
    closeConfirmModal() {
      this.showConfirmModal = false
    },
    logOut() {
      logout()
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  padding: 3.2rem 0;
  @include flex(center, space-between, column);
  width: $sidebar-width;
  background-color: $purple;
  position: relative;
  border-radius: 0 0 3.2rem 0;
  color: $white;

  .help-icon {
      font-size: 3em;
      fill: #fff;
      margin-bottom: 20px;
  }

  @media print {
    display: none;
  }

  .logo {
    width: 100%;
  }

  .routes {
    width: 100%;
    @include flex(flex-end, center, column);
    .link {
      @include flex(flex-end, center, column);
      font-size: 2.4rem;
      font-weight: bold;
      width: 8rem;
      cursor: pointer;
      .round {
        width: 3.2rem;
        height: 3.2rem;
        position: relative;
      }
      .svg {
        border-radius: 1.6rem 0 0 1.6rem;
        width: 100%;
        @include flex(center, center);
        height: 6.4rem;
      }

      &.router-link-exact-active {
        .svg {
          background-color: $background;
          color: $purple;
        }
        .round {
          background-color: $background;
          &:after {
            background-color: $purple;
            content: '';
            width: 6.4rem;
            height: 6.4rem;
            position: absolute;
            border-radius: 3.2rem;
            right: 0;
          }
        }
        .round:first-child::after {
          bottom: 0;
        }
        .round:last-child::after {
          top: 0;
        }
      }
    }
  }

  &:before {
    content: '';
    background-color: $purple;
    width: calc(3.2rem + #{$sidebar-width});
    height: 3.2rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
</style>
