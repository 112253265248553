<script>
  export default {
    name: 'Loading'
  }
</script>

<template>
  <div class="v-loading">
    <img class="loading-background" src="@/assets/loading-background.svg">
    <img class="loading-character" src="@/assets/loading-character.svg">
  </div>
</template>

<style lang="scss">
  .v-loading {
    margin-top: 2rem;
    width: 100%;
    height: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .loading-background {
      height: 100%;
    }
    .loading-character {
      position: absolute;
      height: 90%;
      animation: loading 1s infinite;
    }

    @keyframes loading {
      0% {
        transform: rotate(3deg) translateY(1rem);
      }
      50% {
        transform: rotate(-3deg) translateY(1rem);
      }
      100% {
        transform: rotate(3deg) translateY(1rem);
      }
    }
  }
</style>
