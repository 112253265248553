import firebase from 'firebase/app'
import 'firebase/firestore'
import Vue from 'vue'
import i18n from '../i18n'
import _ from 'lodash'

const db = firebase.firestore()
const collectionDataAnalytique = db.collection('ENTRIES')

const state = {
  entries: []
}

const getters = {
  entries: (state) => {
    return state.entries.map((entry) => {
      return {
        user: entry.user,
        date: entry.inputDate,
        imputationDate: entry.familyAccount === '0002-INVENTAIRE' ? entry.imputationDate : mutations.FORMAT_DATE(entry.imputationDate),
        accountingUnit: entry.accountingUnit,
        resultCenterId: entry.resultCenter.toString().length === 4 ? '0' + entry.resultCenter.toString() : entry.resultCenter.toString(),
        siteId: entry.site,
        family: entry.family,
        amount: entry.amount,
        label: entry.label,
        format: entry.format,
        entryType: entry.entryType ? entry.entryType : i18n.t('manual')
      }
    })
  }
}

const mutations = {
  SET_ENTRIES(state, entries) {
    let result = []

    entries.forEach((el) => {
      if (
        el.imputationDate.length === 0 ||
        el.imputationDate.length > 6 ||
        (el.imputationDate.length === 6 && parseInt(el.imputationDate) > 202208)
      ) {
        // Ex 202209 (Sept 2022)
        result.push(el)
      }
    })

    state.entries = result
  },
  ADD_ENTRY(state, entry) {
    state.entries.concat(entry)
  },
  FORMAT_DATE(date) {
    return `${i18n.t('frenchMonths')[parseInt(date.substring(4, 6)) - 1]} ${date.substring(0, 4)}`
  },
  SET_ENTRIES_RESTIT(state, entries) {
    let result = []

    entries.forEach((el) => {
      if (
        el.imputationDate.length === 0 ||
        el.imputationDate.length > 6 ||
        (el.imputationDate.length === 6 && parseInt(el.imputationDate) > 202208)
      ) {
        // Ex 202209 (Sept 2022)
        result.push(el)
      }
    })

    state.entries = result
  }
}

const actions = {
  fetchEntries({ commit, rootState }, params) {
    let query = firebase.firestore().collection('ENTRIES')
    const start = new Date(rootState.configuration.startDate.toISODate())
    const end = new Date(rootState.configuration.endDate.toISODate())
    const batches = []
    params.format = params.format.concat(
      params.format.map((element) => {
        return element.toUpperCase()
      })
    )
    params.user ? (query = query.where('user', '==', params.user)) : null
    params.format.length > 1 && !params.site.length ? (query = query.where('format', 'in', params.format)) : null
    params.format.length === 1 ? (query = query.where('format', '==', params.format[0])) : null

    if (params.imputationDate) {
      query = query.where('imputationDate', '==', params.imputationDate)
      query.orderBy('imputationDate', 'DESC')
    } else {
      query = query.where('inputDate', '>=', start).where('inputDate', '<=', end)
    }

    if (params.site.length) {
      params.site = params.site.split(',').filter(Boolean)
      while (params.site.length) {
        // firestore limits batches to 10
        const batch = params.site.splice(0, 10)
        // add the batch request to to a queue
        batches.push(
          new Promise((response) => {
            query
              .where('site', 'in', [...batch])
              .get()
              .then((results) => response(results.docs.map((result) => ({ ...result.data() }))))
              .catch((error) => console.error(error))
          })
        )
      }
    } else {
      batches.push(
        new Promise((response) => {
          query
            .get()
            .then((results) => response(results.docs.map((result) => ({ ...result.data() }))))
            .catch((error) => console.error(error))
        })
      )
    }

    // after all of the data is fetched, return it
    Promise.all(batches).then((content) => {
      commit('SET_ENTRIES', content.flat())
    })
  },

  async fetchEntriesRestitute({ commit, rootState }, params) {
    const entries = []
    let query = firebase.firestore().collection('ENTRIES')

    const start = new Date(rootState.configuration.startDate.toISODate())
    const end = new Date(rootState.configuration.endDate.toISODate())

    params.types?.length
      ? (query = query.where(
          'format',
          'in',
          params.types.map((element) => {
            return element.toUpperCase()
          })
        ))
      : null

    if (params.imputationDate) {
      query = query.where('imputationDate', '==', params.imputationDate)
      query.orderBy('imputationDate', 'DESC')
    } else {
      query = query.where('inputDate', '>=', start).where('inputDate', '<=', end)
    }

    if (params.site.length) {
      let tmpParams = [...new Set(params.site)]
      while (tmpParams.length) {
        // firestore limits batches to 10
        const batch = tmpParams.splice(0, 10)
        const firestoreDocs = await query.where('site', 'in', [...batch]).get()
        entries.push(firestoreDocs.docs.map((doc) => doc.data()))
      }
    } else {
      const firestoreDocs = await query.get()
      entries.push(firestoreDocs.docs.map((doc) => doc.data()))
    }

    commit('SET_ENTRIES_RESTIT', entries.flat())
  },

  fetchEntriesByType({ commit, rootState }, file_name) {
    return new Promise(async (resolve, reject) => {
      try {
        collectionDataAnalytique
          .where('entryType', '==', file_name)
          .get()
          .then((entries) => {
            resolve(!!entries.docs.length)
          })
      } catch (error) {
        console.log(error)
        reject()
      }
    })
  },

  async fetchEntriesByParams({ commit, rootState }, params) {
    let query = firebase.firestore().collection('ENTRIES')
    params.site ? (query = query.where('site', '==', params.site)) : null
    params.format ? (query = query.where('format', 'in', params.format)) : null
    params.imputationDate ? (query = query.where('imputationDate', '==', params.imputationDate)) : null
    params.familyAccount ? (query = query.where('familyAccount', '==', params.familyAccount)) : null

    return new Promise((response) => {
      try {
        query.get().then((entries) => response(entries.docs.map((result) => ({ ...result.data() }))))
      } catch (error) {
        console.error(error)
      }
    })
  },

  async fetchEntriesByParamsForAll(_, params) {
    try {
      const promisesList = []
      for (let i = 0; i < params.site.length; i += 10) {
        let sites = params.site.slice(i, i + 10)
        let query = firebase.firestore().collection('ENTRIES')
        query = query.where('site', 'in', sites)
        params.format ? (query = query.where('format', '==', params.format[0])) : null
        params.imputationDate ? (query = query.where('imputationDate', '==', params.imputationDate)) : null
        params.familyAccount ? (query = query.where('familyAccount', '==', params.familyAccount)) : null

        promisesList.push(query.get())
      }
      const res = await Promise.all(promisesList)
      return res.map((res) => res.docs.map((result) => ({ ...result.data() })))
    } catch (error) {
      console.error(error)
    }
  },

  async insertEntries({ commit }, entries) {
    const batches = _.chunk(entries, 500).map((entriesDocs) => {
      const batch = db.batch()

      entriesDocs.forEach((doc) => {
        batch.set(collectionDataAnalytique.doc(), doc)
      })

      return batch.commit()
    })

    await Promise.all(batches)
      .then(() => {
        Vue.toasted.info(`La saisie a bien été enregistrée`)
        commit('ADD_ENTRY', entries)
      })
      .catch((error) => {
        console.error(error)
        Vue.toasted.error(`Erreur lors de l'enregistrement de la saisie`)
      })
  },
  async deleteAndInsertEntries({}, { entries, file_name }) {
    return new Promise(async (resolve, reject) => {
      try {
        const deleteBatchArray = []
        let operationCounter = 0
        let batchIndex = 0

        const documentSnapshotArray = await collectionDataAnalytique.where('entryType', '==', file_name).get()
        deleteBatchArray.push(db.batch())

        //Fill delete
        const insertBatchArray = _.chunk(entries, 500).map((entriesDocs) => {
          const batch = db.batch()

          entriesDocs.forEach((doc) => {
            batch.set(collectionDataAnalytique.doc(), doc)
          })

          return batch.commit()
        })

        //Fill insert
        documentSnapshotArray.forEach((documentSnapshot) => {
          deleteBatchArray[batchIndex].delete(documentSnapshot.ref)
          operationCounter++

          if (operationCounter === 499) {
            deleteBatchArray.push(db.batch())
            batchIndex++
            operationCounter = 0
          }
        })

        const batches = deleteBatchArray.map((b) => b.commit()).concat(insertBatchArray)

        await Promise.all(batches)
          .then(() => {
            Vue.toasted.info(`La saisie a bien été enregistrée`)
            resolve()
          })
          .catch((error) => {
            console.error(error)
            Vue.toasted.error(`Erreur lors de l'enregistrement de la saisie`)
            reject()
          })
      } catch (error) {}
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
