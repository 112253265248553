<template>
  <div class="v-calendar">
    <button :class="{ opened: calendarIsOpen, 'button-calendar': true }" @click="calendarIsOpen = !calendarIsOpen">
      <slot />
    </button>
    <div v-if="calendarIsOpen" class="calendar">
      <div class="month">
        <button @click="shiftMonth(-1)">
          <v-svg>chevron-left</v-svg>
        </button>
        <div class="current-month">{{ displayedMonth.toFormat('MMMM yyyy') }}</div>
        <button :disabled="displayedMonth.plus({ month: 1 }) >= lastSelectableDay" @click="shiftMonth(1)">
          <v-svg>chevron-right</v-svg>
        </button>
      </div>
      <div class="calendar-days">
        <div v-for="(dayLabel, index) of days" :key="`${calendarId}:dayLabel#${index}`" class="cell label">
          {{ dayLabel }}
        </div>
        <div
          v-for="day in emptyCells"
          :key="`${calendarId}:previousMonthDay#${day}`"
          class="cell other-month"
          :class="cssClasses(day, -1)"
          @click="selectDay(day, -1)">
          <div class="number">{{ day }}</div>
        </div>
        <div
          v-for="day in displayedMonth.daysInMonth"
          :key="`${calendarId}:day#${day}#`"
          class="cell"
          :class="cssClasses(day)"
          @click="selectDay(day)">
          <div class="number">{{ day }}</div>
        </div>
        <div
          v-for="day in daysOfNextMonth"
          :key="`${calendarId}:nextMonthDay#${day}`"
          :class="cssClasses(day, 1)"
          class="cell other-month"
          @click="selectDay(day, 1)">
          <div class="number">{{ day }}</div>
        </div>
      </div>
      <div class="actions">
        <button class="validate" @click="validateDates"><v-svg>checkmark</v-svg>Valider</button>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime, Info } from 'luxon'

const days = Info.weekdays('short').map(day => day.replace('.', ''))
let calendarId = 0

export default {
  name: 'VCalendar',
  props: {
    startDate: {
      type: Object,
      required: true
    },
    endDate: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      days,
      calendarId: ++calendarId,
      calendarIsOpen: false,
      justOpened: false,
      displayedMonth: this.startDate.setLocale(document.documentElement.lang).set({ day: 1 }),
      lastSelectableDay: DateTime.local(),
      selectedStartDate: this.startDate,
      selectedEndDate: this.endDate,
      manualSelection: false
    }
  },
  computed: {
    emptyCells() {
      const cells = []
      for (let i = this.displayedMonth.weekday - 1; i > 0; i--) {
        cells.push(this.daysOfPreviousMonth - i + 1)
      }
      return cells
    },
    daysOfPreviousMonth() {
      return this.displayedMonth.minus({ months: 1 }).daysInMonth
    },
    daysOfNextMonth() {
      return 7 - ((this.displayedMonth.daysInMonth + this.emptyCells.length) % 7)
    }
  },
  methods: {
    cssClasses(day, shiftMonth = 0) {
      const date = this.displayedMonth.plus({ month: shiftMonth }).set({ day })
      return {
        selected: this.dateIsSelected(date),
        start: date.hasSame(this.selectedStartDate, 'day'),
        end: date.hasSame(this.selectedEndDate, 'day'),
        disabled: !this.dateIsSelectable(date)
      }
    },
    shiftMonth(shift) {
      this.displayedMonth = this.displayedMonth.plus({ month: shift })
    },
    selectDay(day, shiftMonth = 0) {
      this.manualSelection = true      
      const selectedDay = this.displayedMonth.plus({ month: shiftMonth }).set({ day })
      if (!this.dateIsSelectable(selectedDay)) return

      this.shiftMonth(shiftMonth)
      if (this.selectedEndDate !== this.selectedStartDate || this.justOpened) {
        this.selectedStartDate = selectedDay
        this.selectedEndDate = selectedDay
      } else if (this.selectedStartDate > selectedDay) {
        this.selectedEndDate = this.selectedStartDate
        this.selectedStartDate = selectedDay
      } else {
        this.selectedEndDate = selectedDay
      }
      this.justOpened = false
    },
    selectFullMonth() {
      this.selectedStartDate = this.displayedMonth.set({ day: 1 })
      this.selectedEndDate = this.displayedMonth.set({ day: 1 }).plus({ month: 1 }).minus({ days: 1 })
    },
    validateDates() {
      this.$emit('period-change', {start: this.selectedStartDate, end: this.selectedEndDate.plus({days: 1})})
      this.calendarIsOpen = false
    },
    dateIsSelected(date) {
      return date >= this.selectedStartDate && date <= this.selectedEndDate
    },
    dateIsSelectable(date) {
      return date <= this.lastSelectableDay
    },
    eventCloseCalendar(event) {
      if (!this.$el.contains(event.target)) {
        this.calendarIsOpen = false
      }
    }
  },
  watch: {
    calendarIsOpen() {
      if (this.calendarIsOpen) {
        window.addEventListener('click', this.eventCloseCalendar)
        this.justOpened = true
        this.displayedMonth = this.startDate.setLocale(document.documentElement.lang).set({ day: 1 })
        this.selectFullMonth()
      } else {
        this.manualSelection = false
        window.removeEventListener('click', this.eventCloseCalendar)
      }
    },
    displayedMonth(val) {
      if (!this.manualSelection) this.selectFullMonth()
    }
  },
}
</script>

<style lang="scss" scoped>
.v-calendar {
  z-index: 5;
  position: relative;
  .button-calendar {
    @include flex;
    background: transparent;
    border: none;
    outline: none;
    border: 1px solid transparent;
    border-bottom: none;
    height: 4.4rem;
    border-radius: 0.8rem 0.8rem 0 0;
    padding: 1.5rem 2rem;
    cursor: pointer;
    &:hover {
      background-color: rgba($white, .2);
       border-color: rgba(#c7c4de, .2);
    }
    &.opened {
      height: 5rem;
      // padding-bottom: .3rem;
      background-color: $white;
      border-color: #c7c4de;
    }
    .v-svg {
      margin-right: .4em;
    }
  }
  .calendar {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateY(calc(100% - 1px));
    background-color: $white;
    border-radius: 0.8rem 0 0.8rem 0.8rem;
    border: 1px solid #c7c4de;
    width: 34rem;
    padding: 1.6rem;
    .month {
      @include flex;
      font-family: $primary-font--bold;
      font-size: 2rem;
      height: 3.2rem;
      margin-bottom: 1em;
      text-transform: capitalize;
      button {
        @include flex;
        border: none;
        outline: none;
        background: transparent;
        font-size: 1.2em;
        height: 100%;
        width: 3.2rem;
        &:hover {
          font-size: 1.3em;
        }
      }
    }
    .calendar-days {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      row-gap: 0.2rem;
      justify-items: center;
      .cell {
        @include flex;
        font-size: 1.3rem;
        user-select: none;
        text-transform: capitalize;
        transition: all 200ms;
        width: 100%;
        &.label {
          @extend %font-bold;
        }
        &:not(.label) {
          cursor: pointer;
        }
        &.other-month {
          color: $grey;
        }
        &.disabled {
          color: $dark-grey;
          cursor: not-allowed;
        }
        .number {
          @include flex;
          border-radius: 50%;
          height: 3.2rem;
          width: 3.2rem;
        }
        &.selected {
          position: relative;
          .number {
            color: $white;
          }
          &.start,
          &.end {
            .number {
              background: $purple;
            }
          }
          &:not(.start):before {
            content: '';
            background-color: lighten($purple, 10%);

            position: absolute;
            height: 100%;
            left: 0;
            width: 50%;
            z-index: -1;
          }
          &:not(.end):after {
            content: '';
            background-color: lighten($purple, 10%);
            position: absolute;
            height: 100%;
            right: 0;
            width: 50%;
            z-index: -1;
          }
        }
      }
    }
    .actions {
      margin-top: 1rem;
      @include flex(center, flex-end);

      .validate {
        @include flex;
        transition: 200ms;
        background: transparent;
        border: 1px solid $purple;
        padding: 0.6rem 1.6rem;
        border-radius: 0.8rem;
        @extend %font-bold;
        color: $purple;
        cursor: pointer;
        outline: none;

        &:hover {
          background-color: $purple;
          color: $white;
        }
        .v-svg {
          margin-right: 0.4em;
          font-size: 0.8em;
        }
      }
    }
  }
}
</style>
