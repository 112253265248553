<template>
  <div>
    <div ref="targetElement" class="multi-select">
      <div class="selected-items" @click="toggleDropdown">
        <div v-for="(item, index) in selectedItems" :key="index" class="selected-item">
          {{ item }}
          <span @click.prevent.stop="removeItem(index)">x</span>
        </div>
      </div>

      <div v-if="dropdownVisible" class="dropdown">
        <input v-model="searchText" type="text" :placeholder="$t('search')" @input="filterOptions" />
        <div v-for="(option, index) in filteredOptions" :key="index" @click="toggleSelection(option)">
          {{ option }}
          <span v-if="selectedItems.includes(option)" @click="removeItem(index)">x</span>
        </div>
      </div>
    </div>
    <input :disabled="isBtnDisabled" type="button" :value="$t('selectAllSites')" @click="selectAll()" />
    <input :disabled="selectedItems.length === 0" type="button" :value="$t('deleteAllSites')" @click="removeAll()" />
  </div>
</template>

<script>
  export default {
    name: 'MultiSelect',
    props: {
      options: {
        type: Array,
        default: () => []
      },
      isBtnDisabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectedItems: [],
        dropdownVisible: false,
        searchText: '',
        filteredOptions: []
      }
    },
    watch: {
      options() {
        this.filteredOptions = this.options
        this.selectedItems = []
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      selectAll() {
        this.selectedItems = [...this.filteredOptions]
        this.$emit('selectedItems', this.selectedItems)
      },
      removeAll() {
        this.selectedItems = []
        this.$emit('selectedItems', this.selectedItems)
      },
      toggleDropdown() {
        this.dropdownVisible = !this.dropdownVisible
        if (!this.dropdownVisible) {
          this.searchText = ''
          this.filteredOptions = this.options
        }
      },
      toggleSelection(option) {
        if (!this.selectedItems.includes(option)) {
          this.selectedItems.push(option)
        } else {
          this.selectedItems = this.selectedItems.filter((item) => item !== option)
        }
        this.$emit('selectedItems', this.selectedItems)
      },
      removeItem(index) {
        this.selectedItems.splice(index, 1)
        this.$emit('selectedItems', this.selectedItems)
      },
      filterOptions() {
        this.filteredOptions = this.options.filter((option) => option?.toLowerCase().includes(this.searchText?.toLowerCase()))
      },
      handleClickOutside(event) {
        const targetElement = this.$refs.targetElement
        if (!targetElement.contains(event.target)) {
          this.dropdownVisible = false
        }
      }
    }
  }
</script>

<style scoped>
  input {
    margin-top: 4px;
    margin-right: 4px;
  }
  .multi-select {
    position: relative;
    background-color: white;
  }

  .selected-items {
    border: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
    min-height: 20px;
  }

  .selected-item {
    display: inline-block;
    margin: 2px;
    padding: 2px 5px;
    background-color: #e0e0e0;
    border-radius: 3px;
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    z-index: 1;
    overflow-y: scroll;
    max-height: 25rem;
  }

  .dropdown input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
  }

  .dropdown div {
    padding: 5px;
    cursor: pointer;
  }

  .dropdown div:hover {
    background-color: #e0e0e0;
  }
</style>
