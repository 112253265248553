<template>
  <div v-show="isLoading" class="spinner">
    <div class="lds-dual-ring" />
    <p>{{ message }}</p>
  </div>
</template>

<script>
  export default {
    name: 'Spinner',
    props: {
      isLoading: {
        default: false
      },
      message: {
        type: String
      }
    }
  }
</script>

<style scoped lang="scss">
  .spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: hsla(0, 0%, 100%, 0.6);
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 101;

    > p {
      color: #8f6b9f;
      font-weight: bold;
    }
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #8f6b9f;
    border-color: #8f6b9f transparent #8f6b9f transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
