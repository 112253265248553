
const mixin = {
    methods: {
        /**
         * Go to back to entries with selected format
         * @param {String} selectedFormat  The selected format
         * 
         */
        goBackToEntries(selectedFormat){
            this.$router.push({ name: 'entry', params: {selectedFormat: selectedFormat }})
        } 
    }
}

export default mixin