<template>
  <div id="app" v-if="families.length && validIndicators">
    <sidebar />
    <router-view class="router-view" />
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import globalMixin from '@/mixins/global'

Vue.mixin(globalMixin)

export default {
  name: 'DataAnalytique',
  components: {
    Sidebar
  },
  computed: {
    ...mapState('configuration', ['habilitations', 'families', 'validIndicators', 'alertThreshold']),
  },
  created() {
    if (!this.habilitations) window.location.href = '/error.html'
    else this.fetchParams(this.$userInfos.uid)
  },
  methods: {
    ...mapActions('configuration', ['fetchParams'])
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  background-color: $background;
  @media print {
    background: none;
  }
  .router-view {
    width: 100%;
    height: 100vh;
    border-radius: 32px 0 0 0;
    z-index: 1;
    padding: 6.4rem;
  }
}
</style>
