import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './i18n'
import 'firebase/auth'
import router from './router'
import store from './store'
import '@/components/base'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import VTooltip from 'v-tooltip'


Vue.config.productionTip = false

Vue.use(Toasted, {duration: 5000})
Vue.use(Vuelidate)
Vue.use(VTooltip)

export default function createVueApp({firebaseUser, user, roles}) {
  addPropertiesOnVuejsPrototype({ firebaseUser, user, roles })

  return new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

function addPropertiesOnVuejsPrototype({ firebaseUser, user, roles }) {
  Vue.prototype.$user = firebaseUser
  Vue.prototype.$userInfos = user
  Vue.prototype.$roles = roles
}