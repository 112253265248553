<template>
  <div :class="[`svg-${name}`, 'v-svg']" v-on="$listeners" v-html="content" />
</template>

<script>
export default {
  name: 'VSvg',

  data() {
    return {
      content: null
    }
  },

  computed: {
    name() {
      return this.$slots.default[0].text.trim()
    }
  },

  watch: {
    name: {
      immediate: true,
      async handler() {
        const svgModule = await import(`!svg-inline-loader?classPrefix=true&idPrefix=true!@/assets/svg/${this.name}.svg`)
        this.content = svgModule.default
      }
    }
  }
}
</script>

<style lang="scss">
.v-svg {
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  height: 1em;
}

svg {
  height: 100%;
}
</style>
