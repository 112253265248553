import { DateTime } from 'luxon'

export const blockedEntry = (date, format) => {
  const todayDate = new Date()
  const delayedClosureDate = new Date()
  const closureDate = new Date(formatDate(date.DataAnalytiqueclosureEndDate, 'yyyy-MM-dd'))

  delayedClosureDate.setDate(closureDate.getDate() + date.closureDelay)

  if (format.toUpperCase() !== 'CLOSING' && todayDate.getDate() >= closureDate.getDate()
    && todayDate.getDate() <= delayedClosureDate
    && todayDate.getMonth() === closureDate.getMonth()
    && todayDate.getHours() >= date.closedHour) {
    return true
  }

  return false
}

export const blockEntry = (currentClosureDate, lastClosureDate, format) => {
  if (format && format.openingDay) {
    const currentTimestamp = new Date().getTime()
    const closingHour = new Date(currentClosureDate * 1000).setHours(Number(format.closingEndTime), 0, 0)
    const lastClosureHour = new Date(lastClosureDate * 1000).setHours(Number(format.closingEndTime), 0, 0)
    const openingDayTimestamp = new Date().setDate(Number(format.openingDay))
    
    console.log('closingHour     : '+ new Date(closingHour))
    console.log('lastClosureHour : '+ new Date(lastClosureHour))
    console.log('openingDayTimestamp   : '+ new Date(openingDayTimestamp))

    if (new Date(currentTimestamp).getMonth()+1 === new Date(lastClosureHour).getMonth()+1) {
      if (currentTimestamp >= lastClosureHour && currentTimestamp < openingDayTimestamp) {
        return true
      }
    } else if (new Date(currentTimestamp).getMonth()+1 === new Date(closingHour).getMonth()+1) {
      if (currentTimestamp > closingHour) {
        return true
      }
    }
  }
  return false
}


export const closestGreater = (arr, n, step) => {
  arr = arr.sort(compareNumbers)
  const diff = arr.map(x => x - n)
  const min = Math.min(...diff.filter(x => x > 0))
  return arr[diff.indexOf(min) + step]
}

export const compareNumbers = (a, b) =>{
  return a - b
}

export const formatDate = (date, format = 'dd/MM/yyyy') => {
  const dateInSec = date ? date.seconds : null
  return dateInSec ? DateTime.fromSeconds(dateInSec).toFormat(format) : ''
}

export const formatDateRest = (date, format = 'dd/MM/yyyy HH:mm') => {
  const dateInSec = date ? date.seconds : null
  return dateInSec ? DateTime.fromSeconds(dateInSec).toFormat(format) : ''
}

export const formatAmount = (amount) => {
  return parseFloat(amount.replace(/\s+/g, '').replace(',', '.'))
}

export const pad = (site, size) => {
  let s = '0000' + site
  return s.length > size ? s.substring(s.length - size) : s
}
