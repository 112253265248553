<script>
export default {
  name: 'VButton',
  functional: true,
  render(createElement, { data, children }) {
    return createElement('button', { ...data, class: ['button'].concat(data.class) }, children)
  }
}
</script>

<style lang="scss" scoped>
.button {
  @include flex;
  @extend %font-bold;
  border-radius: 1.6rem;
  padding: 1.6rem 3.2rem;
  font-size: 2rem;
  outline: none;
  background: $button-color;
  transition: 200ms;
  box-shadow: 0px 3px 12px rgba($grey, 0.5);
  border: none;
  color: $white;
  cursor: pointer;

  &:hover {
    background-color: $black;
  }

  &.small {
    padding: 0.8rem 1.6rem;
    font-size: 1.3rem;
    margin: 0.2rem;
  }

  &:disabled {
    background-color: $light-grey;
    cursor: not-allowed;
  }

  &.flat {
    &:hover:not(:disabled) {
      background-color: #0000001a;
    }
    box-shadow: none;
    background: transparent;
    color: $button-color;
    &:focus{
      background-color: $light-grey;
    }
  }

}
</style>
