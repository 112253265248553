<template>
  <div class="pagination-container">
    <div>
      <span class="mr-1">{{ $t('pagination.display') }}</span>
      <select v-model="selectedOption" @change="$emit('update-per-page', selectedOption)">
        <option v-for="value in options" :key="value">{{ value }}</option>
      </select>
      <span class="ml-1">{{ $t('pagination.lines') }}</span>
    </div>
    <div>
      {{ $t('pagination.lines') }} {{firstLinePageNumber}}-{{lastLinePageNumber}} {{ $t('pagination.on') }} {{total}}
    </div>
    <div>
      <ul class="pagination">
        <li class="pagination-item">
          <button
            @click="onClickFirstPage"
            :disabled="isInFirstPage"
          > {{ $t('pagination.first') }} </button>
        </li>

        <li class="pagination-item">
          <button
            @click="onClickPreviousPage"
            :disabled="isInFirstPage"
          > {{ $t('pagination.previous') }} </button>
        </li>

        <li
          v-for="(page, index) in pages"
          :key="index"
          class="pagination-item"
        >
          <button
            @click="onClickPage(page.name)"
            :disabled="page.isDisabled"
          > {{ page.name.toString() }} </button>
        </li>

        <li class="pagination-item">
          <button
            @click="onClickNextPage"
            :disabled="isInLastPage"
          > {{ $t('pagination.next') }} </button>
        </li>

        <li class="pagination-item">
          <button
            @click="onClickLastPage"
            :disabled="isInLastPage"
          > {{ $t('pagination.last') }} </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPagination',
  components: {},
  props: {
    /**
     * maximum number of visible buttons
    */
    maxVisibleButtons: {
      type: Number,
      default: 3
    },
    /**
     * Number of pages
    */
    totalPages: {
      type: Number,
      required: true
    },
    /**
     * Number of elements
    */
    total: {
      type: Number,
      required: true
    },
    /**
     * Number of elem per page
    */
    perPage: {
      type: Number,
      required: true
    },
    /**
     * Selected page
    */
    currentPage: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      options: ['10', '25', '50', '100'],
      selectedOption: '10'
    }
  },
  computed: {
    firstLinePageNumber() {
      return (this.currentPage * this.perPage + 1) - this.perPage
    },
    lastLinePageNumber() {
      return !this.isInLastPage ? (this.currentPage * this.perPage) : this.total
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) { 
        return this.totalPages - this.maxVisibleButtons + 2;
      }

      return this.currentPage - 1;

    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage 
        })
      }

      return range
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);    
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_colors"; 

.pagination-container
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

</style>
