import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/entry',
    name: 'entry',
    component: () => import('@/views/Entry.vue'),
    props: true
  },
  {
    path: '/restitution',
    name: 'restitution',
    component: () => import('@/views/Restitution.vue')
  },
  {
    path: '/entry-demarque',
    name: 'demarque',
    component: () => import('@/views/Entry-Demarque.vue')
  },
  {
    path: '/entry-demarque-invent',
    name: 'demarque-invent',
    component: () => import('@/views/Entry-Demarque-Inventaire.vue')
  },
  {
    path: '/entry-demarque-invent-all',
    name: 'demarque-invent-all',
    component: () => import('@/views/Entry-Demarque-Inventaire-All-Sites.vue')
  },
  {
    path: '/entry-demarque-stk-trad',
    name: 'entry-demarque-stk-trad',
    component: () => import('@/views/Entry-Demarque-Stk-Trad.vue')
  },
  {
    path: '/entry-demarque-frais-trad',
    name: 'entry-demarque-frais-trad',
    component: () => import('@/views/Entry-Demarque-Frais-Trad.vue')
  },
  {
    path: '/entry-logistique',
    name: 'entry-logistique',
    component: () => import('@/views/Entry-Logistique.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
